// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resourceCardContainer {
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.resourceCardMarkdown {
    font-size: 20px;
}

.resourceCardTitle {
    color: black;
    font-size: 30px;
    font-weight: bold;
}

.resourcePage {
    text-align: left;
    font-size: 20px;
    min-height: 100vh;
    max-width: 100vw;
}

.reactMarkdown {
    max-width: 100vw;
}

.reactMarkdown img {
    max-width: 90vw;
}`, "",{"version":3,"sources":["webpack://./src/app/resource/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".resourceCardContainer {\n    text-align: left;\n    padding-left: 40px;\n    padding-right: 40px;\n    padding-top: 40px;\n    padding-bottom: 40px;\n}\n\n.resourceCardMarkdown {\n    font-size: 20px;\n}\n\n.resourceCardTitle {\n    color: black;\n    font-size: 30px;\n    font-weight: bold;\n}\n\n.resourcePage {\n    text-align: left;\n    font-size: 20px;\n    min-height: 100vh;\n    max-width: 100vw;\n}\n\n.reactMarkdown {\n    max-width: 100vw;\n}\n\n.reactMarkdown img {\n    max-width: 90vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
