// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closedContainer {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.homeSection {
    text-align: left;
    margin-left: 100px;
    margin-right: 100px;

    @media only screen and (max-width: 600px) {
        margin-left: 10px;
        margin-right: 10px;
    }  
}

.homeSectionHeader {
    font-size: 34px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
    }  
}

.homeSectionText {
    line-height: 150%;
    font-size: 27px;

    @media only screen and (max-width: 600px) {
        font-size: 22px;
    }  
}

.homeWelcomeTransitionSection {
    height: 480px;

    @media only screen and (max-width: 600px) {
        height: 240px;
    }  
}

.homeTransitionSection {
    height: auto;
}

.homeTechnologyIconGrid {
    text-align: center;
    line-height: 150%;
    font-size: 27px;
}

.homeTechnologyIconGridText {
    font-size: 27px;
}

.sitesContainer {
    padding-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/home/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;;IAEnB;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;;IAEjB;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,iBAAiB;IACjB,eAAe;;IAEf;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,aAAa;;IAEb;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".closedContainer {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-top: 10px;\n}\n\n.homeSection {\n    text-align: left;\n    margin-left: 100px;\n    margin-right: 100px;\n\n    @media only screen and (max-width: 600px) {\n        margin-left: 10px;\n        margin-right: 10px;\n    }  \n}\n\n.homeSectionHeader {\n    font-size: 34px;\n    font-weight: bold;\n\n    @media only screen and (max-width: 600px) {\n        font-size: 30px;\n    }  \n}\n\n.homeSectionText {\n    line-height: 150%;\n    font-size: 27px;\n\n    @media only screen and (max-width: 600px) {\n        font-size: 22px;\n    }  \n}\n\n.homeWelcomeTransitionSection {\n    height: 480px;\n\n    @media only screen and (max-width: 600px) {\n        height: 240px;\n    }  \n}\n\n.homeTransitionSection {\n    height: auto;\n}\n\n.homeTechnologyIconGrid {\n    text-align: center;\n    line-height: 150%;\n    font-size: 27px;\n}\n\n.homeTechnologyIconGridText {\n    font-size: 27px;\n}\n\n.sitesContainer {\n    padding-top: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
